var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-app-bar',{staticClass:"pr-0",staticStyle:{"background-color":"#EEF1FF"},attrs:{"app":"","color":"secondary","elevate-on-scroll":"","height":"80"}},[_c('v-app-bar-nav-icon',{staticStyle:{"margin-right":"8px"},on:{"click":function($event){$event.stopPropagation();return _vm.drawerAction.apply(null, arguments)}}},[_c('v-img',{attrs:{"contain":"","height":"26","src":require('@/assets/icons/dashboard-menu.png')}})],1),_c('v-row',{staticStyle:{"background-color":"#EEF1FF"},attrs:{"align-content":"center","justify":"space-between"}},[_c('span',{staticClass:"route-name"},[_vm._v(_vm._s(this.$route.meta.label))]),_c('div',{staticClass:"left-container"},[_c('div',{staticClass:"welcome-message mr-12"},[_vm._v(" Welcome Back! "),_c('span',{staticStyle:{"font-weight":"900"}},[_vm._v(_vm._s(_vm.loggedInUser.first_name || ''))])]),_c('v-card',{staticClass:"window-menu-card",attrs:{"rounded":""}},[_c('v-icon',{staticStyle:{"margin-left":"14px"},attrs:{"size":"30"},on:{"click":_vm.openTrackShipmentDialog}},[_vm._v("mdi-map-marker-outline")]),_c('v-badge',{attrs:{"color":"error","content":_vm.unReadNotifications.length,"offset-x":"24","offset-y":"24"}},[_c('v-icon',{staticStyle:{"padding":"14px"},attrs:{"size":"30"},on:{"click":_vm.openNotificationNav}},[_vm._v("mdi-bell-outline")])],1),_c('v-icon',{attrs:{"size":"30"},on:{"click":function($event){return _vm.gotoPage('user-account')}}},[_vm._v("mdi-account-multiple-outline")]),_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticStyle:{"padding-right":"10px"}},'v-icon',attrs,false),on),[_vm._v("mdi-dots-horizontal")])]}}])},[_c('v-list',[(_vm.account.role != 'C')?_c('v-list-item',{attrs:{"to":_vm.account.role == 'SA'
                    ? { name: 'staff-dashboard' }
                    : { name: 'package-manager' }}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-shield-crown-outline")])],1),_c('v-list-item-title',[_vm._v("Admin Portal ")])],1):_vm._e(),_c('v-divider'),_c('v-list-item',{on:{"click":_vm.goToLogout}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-exit-to-app")])],1),_c('v-list-item-title',[_vm._v("LOGOUT ")])],1)],1)],1)],1),_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
                    var attrs = ref.attrs;
return [_c('v-card',_vm._g(_vm._b({staticClass:"mobile-menu-card pa-1 mr-1",attrs:{"rounded":"","color":"white"}},'v-card',attrs,false),on),[_c('v-icon',{staticClass:"mobile-menu-card mt-n3",attrs:{"size":"40","color":"primary"}},[_vm._v("mdi-menu-open")]),_c('span',{staticStyle:{"color":"#304967"}},[_vm._v("Menu")])],1)]}}])},[_c('v-list',[_c('v-list-item',{on:{"click":_vm.openTrackShipmentDialog}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-map-marker-outline")])],1),_c('v-list-item-title',[_vm._v("Track Shipment ")])],1),_c('v-list-item',{on:{"click":_vm.openNotificationNav}},[_c('v-list-item-icon',[_c('v-badge',{attrs:{"color":"error","content":_vm.unReadNotifications.length}},[_c('v-icon',[_vm._v("mdi-bell-outline")])],1)],1),_c('v-list-item-title',[_vm._v("Notification ")])],1),_c('v-list-item',{on:{"click":function($event){return _vm.gotoPage('user-account')}}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-account-multiple-outline")])],1),_c('v-list-item-title',[_vm._v("Profile ")])],1),(_vm.account.role != 'C')?_c('v-list-item',{attrs:{"to":_vm.account.role == 'SA'
                  ? { name: 'staff-dashboard' }
                  : { name: 'package-manager' }}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-shield-crown-outline")])],1),_c('v-list-item-title',[_vm._v("Admin Portal ")])],1):_vm._e(),_c('v-list-item',{on:{"click":_vm.goToLogout}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-exit-to-app")])],1),_c('v-list-item-title',[_vm._v("LOGOUT ")])],1)],1)],1)],1),_c('track-shipment',{ref:"trackshipmentform"})],1)],1),_c('v-navigation-drawer',{attrs:{"right":"","width":"350","temporary":"","app":""},model:{value:(_vm.notificationNav),callback:function ($$v) {_vm.notificationNav=$$v},expression:"notificationNav"}},[_c('notification',{attrs:{"unReadNotifications":_vm.unReadNotifications,"readNotifications":_vm.readNotifications,"errorMessage":_vm.errorMessage},on:{"genNotifications":_vm.genNotifications,"closeNav":function($event){_vm.notificationNav = false}}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }