<template>
  <v-main>
    <!-- <layout-drawer /> -->

    <v-responsive class="overflow-visible">
      <div class="pa-0">
        <v-fade-transition mode="out-in">
          <router-view></router-view>
        </v-fade-transition>
      </div>
    </v-responsive>
  </v-main>
</template>

<script>
export default {
  name: 'LayoutView',
};
</script>
