



































































import Vue from 'vue';
import { mapActions } from 'vuex';
export default Vue.extend({
  name: 'Track-Shipment',
  data() {
    return {
      dialog: false,
      loading: false,

      trackingNumber: '',
      errorMessage: '',
      packageStaus: ''
    };
  },
  computed: {
    errorOccurred(): boolean {
      return this.errorMessage.length > 0;
    },
    searchResponse(): boolean {
      return this.packageStaus.length > 0;
    },

    userID() {
      const userInfo = JSON.parse(localStorage.getItem('user')!);
      const userId = userInfo == null ? null : userInfo.pk;
      return userId;
    }
  },
  methods: {
    ...mapActions({
      trackShipments: 'shipment/trackShipments'
    }),
    open() {
      this.dialog = true;
    },
    close() {
      this.dialog = false;
      this.errorMessage = '';
      this.packageStaus = '';
      this.trackingNumber = '';
    },
    getShipmentInfo() {
      this.loading = true;
      this.trackShipments(this.trackingNumber)
        .then((data) => {
          if (data[0].user == this.userID) {
            if (data[0].status == 'OT') {
              this.packageStaus = 'Package is in Overseas Transit';
            } else if (data[0].status == 'JW') {
              this.packageStaus = 'Package is in the Jamaican Warehouse';
            } else if (data[0].status == 'LT') {
              this.packageStaus = 'Package is in Local Transit';
            } else if (data[0].status == 'RP') {
              this.packageStaus = 'Your package is Ready For Pickup';
            } else if (data[0].status == 'RO') {
              this.packageStaus = 'Your package is Received at Origin';
            } else if (data[0].status == 'PS') {
              this.packageStaus = 'Your package is Preparing for Shipment';
            } else if (data[0].status == 'ITD') {
              this.packageStaus = 'Your package is in Transit to Destination';
            } else if (data[0].status == 'AD') {
              this.packageStaus = 'Your package is arrived at Destination';
            } else if (data[0].status == 'PC') {
              this.packageStaus = 'Your package is in Processing Customs';
            } else if (data[0].status == 'RC') {
              this.packageStaus = 'Your package is Released from Customs';
            } else if (data[0].status == 'HD') {
              this.packageStaus = 'Your package is Held by Customs';
            } else if (data[0].status == 'DC') {
              this.packageStaus = 'Your package is Delivered to Courier';
            } else if (data[0].status == 'D') {
              this.packageStaus = 'Your package is Delayed';
            } else if (data[0].status == 'P') {
              this.packageStaus = 'This package has already been picked up.';
            } else {
              this.packageStaus = 'Package was not found in our system.';
            }
          } else {
            this.packageStaus = 'Package was not found in our system.';
          }
        })
        .catch((error: any) => {
          this.packageStaus =
            "Package hasn't been found. Please try again later";
        })
        .finally(() => {
          this.loading = false;
        });
    }
  }
});
