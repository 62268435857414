
































































































































import Vue from 'vue';

export default Vue.extend({
  name: 'Drawer',
  props: ['mini'],
  data() {
    return {
      drawer: false
    };
  },

  computed: {
    screenWidth() {
      return window.innerWidth > 600;
    }
  },

  methods: {
    gotoPage(routeName: string) {
      this.$router.push({ name: routeName });
    },
    open(value: boolean) {
      this.drawer = value;
    }
  }
});
