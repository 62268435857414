






















































































































































import { timeAgo } from '@/utils/functions';
import Vue, { PropType } from 'vue';
import { INotificationRecipients, Notification } from '@/models/notification';
import { mapActions } from 'vuex';
import { ErrorManager } from '@/models/error';
import { Account } from '@/models/account';

export default Vue.extend({
  props: {
    errorMessage: {
      type: String,
      default: () => '',
    },
    unReadNotifications: {
      type: Array as PropType<Notification[]>,
      default: () => [],
    },
    readNotifications: {
      type: Array as PropType<Notification[]>,
      default: () => [],
    },
  },
  data() {
    return {
      localErrorMeesage: this.errorMessage,
      loading: false,
    };
  },
  computed: {
    errorOccurred(): boolean {
      return this.localErrorMeesage.length > 0;
    },
    userID(): number {
      const user = JSON.parse(localStorage.getItem('user') || '').pk;
      return user;
    },
  },
  methods: {
    ...mapActions({ updateNotification: 'notification/updateNotification' }),
    timeAgo,

    async markAsRead(notification?: Notification) {
      this.loading = true;
      if (notification) {
        const readNotif = await this.convertNotificationToRead(notification);
        await this.updateNotification(readNotif).catch((error: any) => {
          this.localErrorMeesage = ErrorManager.extractApiError(error);
        });
      } else {
        Promise.all(
          this.unReadNotifications.map(async (unreadNotif) => {
            const readNotif = await this.convertNotificationToRead(unreadNotif);
            return this.updateNotification(readNotif);
          })
        ).catch((error: any) => {
          this.localErrorMeesage = ErrorManager.extractApiError(error);
        });
      }
      this.loading = false;
    },

    async convertNotificationToRead(
      notification: Notification
    ): Promise<Notification> {
      const mutatedObj = notification.recipients.map(
        (recipient: INotificationRecipients) => {
          if ((recipient.user! as Account).pk == this.userID) {
            recipient.is_read = true;
          }
          return {
            pk: recipient.pk,
            user: (recipient.user! as Account).pk,
            is_read: recipient.is_read,
          };
        }
      );
      notification.recipients = mutatedObj;

      return notification;
    },
  },
});
