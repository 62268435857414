










import Vue from 'vue';
import Drawer from '../app/Drawer.vue';
import AppBar from '../app/AppBar.vue';
import LayoutView from '../app/View.vue';

export default Vue.extend({
  components: {
    Drawer,
    AppBar,
    LayoutView,
  },
  data() {
    return {
      drawerAction: false,
    };
  },
  methods: {
    drawerChange() {
      (this as any).$refs.drawerControl.open(true);

      this.drawerAction = !this.drawerAction;
      return this.drawerAction;
    },
  },
});
