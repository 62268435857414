














































































































































































import { mapActions, mapGetters, mapState } from 'vuex';
import { Auth } from '@/models/auth';
import Vue from 'vue';
import TrackShipment from '@/components/dashboard/TrackShipment.vue';
import Notification from '@/components/notification/Notification.vue';
import { ErrorManager } from '@/models/error';
import { INotification } from '@/models/notification';

// Utilities

export default Vue.extend({
  components: { TrackShipment, Notification },
  name: 'AppBar',

  data() {
    return {
      notificationNav: false,
      errorMessage: '',
      count: 0,
    };
  },

  computed: {
    ...mapState('notification', ['notification']),
    ...mapState('account', ['account']),

    ...mapGetters({
      getUnreadNotifications: 'notification/getUnReadNotifications',
      getReadNotifications: 'notification/getReadNotifications',
    }),

    loggedInUser(): Auth {
      return JSON.parse(localStorage.getItem('user')!);
    },
    screenWidth() {
      return window.innerWidth > 600;
    },

    unReadNotifications(): INotification[] {
      return this.getUnreadNotifications;
    },

    readNotifications(): INotification[] {
      return this.getReadNotifications;
    },
  },

  methods: {
    ...mapActions({
      logout: 'auth/logout',
      fetchNotifications: 'notification/fetchNotifications',
      fetchAccount: 'account/fetchAccount',
    }),

    openNotificationNav() {
      this.notificationNav = true;
    },

    drawerAction() {
      this.$emit('drawerChange');
    },

    gotoPage(routeName: string) {
      this.$router.push({ name: routeName });
    },

    openTrackShipmentDialog() {
      (this as any).$refs.trackshipmentform.open();
    },

    goToLogout() {
      this.logout().finally(() => {
        this.$router.push({ name: 'login' });
      });
    },

    genNotifications() {
      this.fetchNotifications().catch((error: any) => {
        this.errorMessage = ErrorManager.extractApiError(error);
      });
    },

    genUserAccount() {
      this.fetchAccount(this.loggedInUser.pk).catch((error: any) => {
        this.errorMessage = ErrorManager.extractApiError(error);
      });
    },
  },
  mounted() {
    this.genNotifications();
    this.genUserAccount();
  },
});
